<template>
  <div class="">
    <v-card class="shadow-off" :loading="loading" :disabled="loading" loader-height="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <v-btn small color="fbcPrimary" class="mr-1 white--text" v-if="$nova.hasRight(right, 'edit') || $nova.hasRight(right, 'add')" 
            @click="locationdialog = true;locationdata = {};locationdataerror= {};">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <span>Add {{$store.state.LocationHeader ? $store.state.LocationHeader  :'Location'}}</span>
          </v-btn>
          <v-btn class="shadow-off" text @click="refreshData()" small icon><v-icon>mdi-refresh</v-icon></v-btn>
        </div>

        <DataTable :headers="headers" :items="formatedData" :enableslot="['action']" :loading="loading" :bgColor="true">
          <template v-slot:action="{ item }">
            <div class="d-flex align-center justify-center">
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" v-if="$nova.hasRight(right, 'edit')" @click.stop="editLocation(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-if="!item.status && $nova.hasRight(right, 'enabledisable')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="error" v-bind="attrs" v-on="on">
                    <v-icon @click="enableDisable(item._id, 'enable')">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                <span>Enable</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if="!item.status && $nova.hasRight(right, 'read')">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" v-bind="attrs" v-on="on" >mdi-close-circle</v-icon>
                </template>
                <span>Disable</span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if=" $nova.hasRight(right, 'enabledisable')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="success" v-bind="attrs" v-on="on">
                    <v-icon @click="enableDisable(item._id, 'diable')">mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span> Diable </span>
              </v-tooltip>
              <v-tooltip bottom content-class="tooltip-bottom" v-else-if="$nova.hasRight(right, 'read')">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon  color="success" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
                </template>
                <span>Enable</span>
              </v-tooltip>
            </div>
          </template>
        </DataTable>
      </v-card-text>
    </v-card>

    <lb-dailogboxnew v-model="locationdialog" :heading="locationdata._id ? $store.state.LocationHeader ? 'Update '+$store.state.LocationHeader  :'Update Location' : $store.state.LocationHeader ? 'Add New '+$store.state.LocationHeader  :'Add New Location'" width="600"
      :loading="locationdialogloading">
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="locationdata.name" :label="$store.state.LocationHeader ? $store.state.LocationHeader+ ' Name*'  : 'Location Name*'" :error="locationdataerror.name" />
            </v-col>

            <v-col cols="12" class="my-0 py-1">
              <lb-string v-model="locationdata.description" :label="$store.state.LocationHeader ? $store.state.LocationHeader +' Description*'  :'Location Description*'"
                :error="locationdataerror.description" />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown v-model="locationdata.country" :items="countryList" :label=" $store.state.LocationHeader ? $store.state.LocationHeader +' Country*'  :'Location Country'"
                :error="locationdataerror.country" itemtext="name" itemvalue="value"
                 />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown v-model="locationdata.type" :items="locationtypeItem" :label="$store.state.LocationHeader ? $store.state.LocationHeader + ' Type*'  :'Location Type*'"
                :error="locationdataerror.type" itemtext="name" itemvalue="value"
                @change="locationNew(locationdata.type)" />
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" v-if="locationdata._id" @click="updateLocation(locationdata)">
          Update
        </v-btn>
        <v-btn small color="btnColor" class="white--text" v-else @click="addLocation()">
          Add
        </v-btn>
      </template>
    </lb-dailogboxnew>

    <lb-dailogboxnew v-model="locationtypedialog" :heading="'Add New Location Type'" width="600">
      <template v-slot:body>
        <v-col cols="12" class="my-0 py-1">
          <lb-string v-model="locationdata.newtype" label="Location Type" :error="locationdataerror.newtype" />
        </v-col>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn small color="btnColor" class="white--text" >
          <span @click="locationtypeAdd(locationdata.newtype)">Add</span>
        </v-btn>
      </template>
    </lb-dailogboxnew>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "financialclose_location_master",
      locationdialog: false,
      locationdialogloading: false,
      locationdata: {},
      locationdataerror: {},
      loading: false,
      locationtypeItem: [],
      formatedData: [],
      locationtypedialog: false,
      headers: [
        {
          value: "name",
          text: "Name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "description",
          text: "Description",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
      listdata: [],
      countryList:[],
    };
  },
  created() {
    this.refreshData();
  },
  components: {
    DataTable,
  },
  methods: {
  refreshData(){
    this.getData();
  },
    formateData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      console.log(this.listdata, "FOR");
      this.listdata.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexno: index + 1,
          name: item.name,
          description: item.description,
          country: item.country,
          type: item.type,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    getData() {
      this.loading = true;
      this.$store.commit("setProjectName", "");
      this.axios
        .post("/v2/financialclose/location/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.listdata = ele.data.data;
            console.log(this.listdata, "listdata");
            this.formateData();
            this.axios
              .post("/v2/financialclose/location/formselects")
              .then((ele) => {
                let locationtypelist = ele.data.data;
                this.locationtypeItem = locationtypelist[0].type;
                this.countryList = locationtypelist[0].countryList;
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addLocation() {
      this.loading = true;
      this.axios
        .post("/v2/financialclose/location/add", { data: this.locationdata })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.locationdialog = false;
            this.$store.commit("sbSuccess", "added successfully");
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.locationdataerror = ele.data.data[0].index0;
            } else {
              this.$store.commit("sbError", ele.data.message || "Location not added");
              throw new Error(ele.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editLocation(item) {
      this.locationdata = {...item};
      this.locationdata.type = item.type;
      let typeObj = {
        name: this.locationdata.type,
        value: this.locationdata.type,
      };
      this.locationtypeItem.push(typeObj);

      this.locationdialog = true;
      this.locationdataerror = {};
      console.log(item);
    },
    updateLocation(item) {
      console.log(item,"DD");
      this.loading = true;
      this.axios
        .post("v2/financialclose/location/edit/"+ item._id, {  data: item })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.locationdialog = false;
            this.$store.commit("sbSuccess", "update successfully");
            this.getData();
          } else {
           if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.locationdataerror = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    locationNew(name) {
      if (name === "Other") {
        this.locationtypedialog = true;
      }
    },
    locationtypeAdd(type) {
      let typeObj = { name: type, value: type };
      this.locationtypeItem.push(typeObj);
      this.locationdata.type = type;
      this.locationtypedialog = false;
    },
    enableDisable(id, action) {
      this.loading = true;
      let url = "/v2/financialclose/location/disable/";
      if (action === "enable") {
        url = "/v2/financialclose/location/enable/";
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if (action === "enable") {
              this.getData();
              this.$store.commit("sbSuccess", "Item enabled");
            } else {
              this.getData();
              this.$store.commit("sbSuccess", "Item disabled");
            }
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>